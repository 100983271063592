import { MutableRefObject } from "react";
import socketIOClient, { Socket } from "socket.io-client";

export interface BaseClientToServerEvents {
    PING: () => void;
}

export function connectToSocket<T, P extends BaseClientToServerEvents = BaseClientToServerEvents>
(socketRef: MutableRefObject<Socket<T, P | BaseClientToServerEvents> | null>, token: string):
    { socket: Socket<T, P>, pingTimer: NodeJS.Timer } {
    if (socketRef && !socketRef.current?.connected) {
        console.log(`Initializing socket... for ${process.env.REACT_APP_BASE_URL}`);
        const extraHeaders = { authorization: `Bearer ${token}` };
        if (process.env.REACT_APP_BASE_URL === ".") {
            socketRef.current = socketIOClient({extraHeaders});
        } else {
            socketRef.current = socketIOClient(`${process.env.REACT_APP_BASE_URL}`, {extraHeaders});
        }
    } else {
        console.log(`Socket already initialized, continuing...`);
    }

    const socket = socketRef.current;

    const timer = setInterval(() => {
        // @ts-ignore
        socket?.emit("PING");
    }, 1000 * 30);

    return {socket: socketRef.current!, pingTimer: timer};
}
