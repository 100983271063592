import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import { sendPost } from "../utils/utils";
import { useAuth } from "../auth";

function GetMoney() {

    const {token} = useAuth();

    const [amount, setAmount] = useState<number | undefined>(0);
    const [receiveMoneyStatus, setReceiveMoneyStatus] = useState<{type?: "error" | "success", fee?: number, text?: string}>({});

    const receiveAmount = async (amount: number, token: string): Promise<{fee: number}> => {
        const body = {
            amount,
        }
        return await sendPost(`${process.env.REACT_APP_BASE_URL}/api/admin_transaction`, body, token);
    }

    const handleReceiveSubmit = async (e: any) => {
        e.preventDefault();
        console.log(`inside admins' receiveMoneySubmit, amount is ${amount}`);
        try {
            if (!amount) {
                setReceiveMoneyStatus({type: "error", text: "amount must be set"});
                setAmount(undefined);
            } else {
                await receiveAmount(amount, token!);
                setReceiveMoneyStatus({type: "success", text: `You got ${amount} to your account :)`});
            }
        } catch (err: any) {
            setReceiveMoneyStatus({type: "error", text: `problem with receiving money: ${err.message}`});
        } finally {
            setAmount(0);
        }
    }


    return (
        <Container>
            <Row className={"mt-5"}>
                <Col md={{ span: 4, offset: 4 }}>
                    <h3>Get money</h3>
                    <Form onSubmit={handleReceiveSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {"Amount"}
                            </Form.Label>
                            <Form.Control
                                onChange={e => setAmount(parseInt(e.target.value))}
                                type="number"
                                value={amount}
                            />
                        </Form.Group>
                        <Button type={"submit"} variant={"info"}>Submit</Button>
                        {receiveMoneyStatus?.type === 'success' && <p style={{color: "limegreen"}}>{receiveMoneyStatus?.text}</p>}
                        {receiveMoneyStatus?.type === 'error' && <p style={{color: "orangered"}}>{receiveMoneyStatus?.text}</p>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default GetMoney;
