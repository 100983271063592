import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { FlagProvider } from "@unleash/proxy-client-react";
import { UNLEASH_PROXY_CONFIG } from "./components/utils/unleash-proxy";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    console.log(`new path is ${path}`);
    history.replace(path);
}
ReactDOM.render(
  <React.StrictMode>
      <FlagProvider config={UNLEASH_PROXY_CONFIG}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </FlagProvider>
  </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
