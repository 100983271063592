import React from "react";
import "./toggle-switch.css";

interface toggleProps {
    label: string,
    userMode: boolean,
    setUserMode: Function
}


const ToggleSwitch = ({ label, userMode, setUserMode }: toggleProps) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setUserMode((prevMode: boolean) => !prevMode);
        localStorage.setItem("user_mode", e.target.checked.toString())
    }
    return (
        <div className="container">
            {label}{" "}
            <div className="toggle-switch">
                <input type="checkbox" className="checkbox" checked={userMode}
                       name={label} id={label} onChange={handleChange}/>
                <label className="label" htmlFor={label}>
                    <span className="inner" />
                    <span className="switch" />
                </label>
            </div>
        </div>
    );
};

export default ToggleSwitch;