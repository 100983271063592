import React, { useEffect, useState } from 'react';

import './login.css';
import { useAuth } from "./auth";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import GoogleButton from "react-google-button";

type Credentials = {
    username: string | undefined;
    password: string | undefined;
}

async function loginUser(credentials: Credentials) {
    console.log("logging in user...");
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
    if (res.ok) {
        console.log("user logged in successfully");
        return res.json()
    }
    throw new Error("Bad status from fetch (login):" + res.status);
}

export default function Login() {
    const {setToken, setTokenInvalid} = useAuth();
    const [username, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [err, setErr] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const {access_token: token} = await loginUser({
                username,
                password
            });
            setToken(token);
            setTokenInvalid(false);
            navigate("/dashboard", {replace: true});
        } catch (err) {
            console.error("in handleSubmit, caught err:", err);
            setErr(`${err}`);
        }
    }

    const handleGoogleLogin = async () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/auth/google`, "_self");
    }

    useEffect(() => {
        console.log("inside login");
    }, []);

    return(
        <Container fluid style={{backgroundColor: "#cc66ff", color: "white"}}>
            <Row>
                <Col xs={{span: 2, offset: 1}} className={"mt-5"}>
                    <img src={"https://about.emaohi.com/images/profiles_logos/rabbit1.png"} alt={"rabbitCoin"} width={"50%"} />
                </Col>
                <Col md={{ offset: 2 }} className={"mt-2"}>
                    <h1>Please Log In</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={"4"} md={{ offset: 4 }}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={{ offset: 2 }}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        onChange={e => setUserName(e.target.value)}
                                        className="w-50" type="text"
                                        placeholder="Enter username"
                                        style={{textAlign:"center"}}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ offset: 2 }}>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control
                                        onChange={e => setPassword(e.target.value)}
                                        className="w-50" type="password"
                                        placeholder="Password"
                                        style={{textAlign:"center"}}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{offset: 4}}>
                                <Button variant="success" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col md={{span: 1}}>
                    OR <GoogleButton onClick={handleGoogleLogin}/>
                </Col>
            </Row>
            <Row>
                <p style={{color:"white"}}>{err}</p>
            </Row>
        </Container>
    )
}
