import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../auth";
import { sendGet } from "../utils/utils";

function ServiceStatus() {

    const {token} = useAuth();

    const [mainStatus, setMainStatus] = useState<"unknown" | "up" | "down">("unknown");
    const [healthRes, setHealthRes] = useState<HealthRes>();

    type HealthRes = {
        status: "success" | "error",
        info: Record<string, { status: "up" | "down" }>,
        details: Record<string, { status: "up" | "down", message: string }>,
    }

    const fetchServiceStatus = useCallback(async () => {
        try {
            const res = await sendGet<HealthRes>(`${process.env.REACT_APP_BASE_URL}/health`, token!);
            console.log(`res of fetchServiceStatus is ${JSON.stringify(res)}`);
            setHealthRes(res);
            if (res.status === "error") {
                setMainStatus("down");
            } else {
                setMainStatus("up");
            }
        } catch (err) {
            console.error(`err in fetchServiceStatus: ${(err as any).message}`);
            setMainStatus("unknown");
        }
    }, [token, setHealthRes, setMainStatus]);

    useEffect(() => {
        fetchServiceStatus()
            .then(() => {
                console.log(`finished fetchServiceStatus`);
            });
    }, [fetchServiceStatus])

    return (
        <Container>
            <Row className={"m-3"}>
                <Col md={{ span: 4, offset: 4 }}>
                    <h1>Status: <span style={{color: mainStatus === "unknown" ? "grey" : mainStatus === "down" ? "red" : "green"}}>
                        {mainStatus.toUpperCase()}
                    </span></h1>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 4, offset: 4 }}>
                    <ListGroup>
                        {healthRes &&
                        Object.entries(healthRes.details).map(([check, detail], i) => {
                            return <ListGroupItem key={i} variant={detail.status === "up" ? "success" : "danger"}>
                                <b>{check}: </b>{detail.status} {detail.message && <span>({detail.message})</span>}
                            </ListGroupItem>
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default ServiceStatus;
