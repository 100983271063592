import { Link, Outlet } from "react-router-dom";
import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { RequireAdmin, useAuth } from "./auth";

function Layout({counter}: {counter: React.MutableRefObject<number>}) {

    const {removeToken, tokenInvalid, user, expireAt} = useAuth();
    const isAdmin: boolean = (user !== null) && (user.role === "admin" || user.role === "owner");

    console.log(`in layout rendering... isAdmin is ${isAdmin} - ${user?.role}`);

    function logout() {
        console.log("logging out...");
        counter.current = 0;
        removeToken();
    }

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={"mb-3"}>
                <Container>
                    <Navbar.Brand href="#home">
                        <Nav.Link as={Link} to="/dashboard" style={{color: "white"}}>RabbitCoin</Nav.Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/transactions">Transactions</Nav.Link>
                            <Nav.Link as={Link} to="/users">Users</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link as={Link} to="/about">About</Nav.Link>
                            <RequireAdmin>
                            <NavDropdown title="Admin" id="collasible-nav-dropdown">
                                <NavDropdown.Item>
                                    <Link to="/create_user">Create User</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                    <Link to="/get_money">Get Money</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                    <Link to="/service_status">Status</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                    <Link to="/live_updates">Live Updates</Link>
                                </NavDropdown.Item>
                            </NavDropdown>
                            </RequireAdmin>
                            <NavDropdown title={user!.username} id="collasible-nav-dropdown">
                                <NavDropdown.Item>
                                    <Link to="/profile" className={"link-primary"}>Profile</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <Button onClick={logout} variant={"link"} style={{color: "red"}}>Logout</Button>
                            </NavDropdown>
                            {tokenInvalid && <p style={{color: "orangered"}}>token is no longer valid, please re-login</p>}
                            {!tokenInvalid && expireAt && <p style={{color: "#eed202"}}>{`token expires at ${expireAt}`}</p>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet/>
        </>
    );
}

export default Layout;
