import React, { useState } from "react";
import { ListGroup, Pagination, Row } from "react-bootstrap";
import { TxItemType, User } from "./utils/types";
import TxItem from "./tx-item";

export default function PaginatedTransactions({txs, user}: {txs: TxItemType[], user: User}) {

    console.log(`Rendered PaginatedTransactions, for ${txs.length} transactions, user is ${user.username}`);

    const pageSize = 3;

    const [currPage, setCurrPage] = useState(1);

    const actualTxCount = txs.length;
    const pageCount = actualTxCount / pageSize + ((actualTxCount % pageSize) ? 1 : 0);

    console.log("page count is", pageCount);

    const createPaginationItems = () => {
        const items = [];
        for (let pageNum = 1; pageNum <= pageCount; pageNum++) {
            items.push(
                <Pagination.Item key={pageNum} active={pageNum === currPage} onClick={() => setCurrPage(pageNum)}>
                    {pageNum}
                </Pagination.Item>,
            );
        }
        return items;
    }

    return (
        <>
            <Row className={"mt-3"}>
                <Pagination>{createPaginationItems()}</Pagination>
            </Row>
            <Row>
                <ListGroup>
                    {txs && txs.slice(currPage * pageSize - pageSize, currPage * pageSize).map(item =>
                        <TxItem key={item.txId} delay={-1} tx={item} user={user}/>
                    )}
                </ListGroup>
            </Row>


        </>
    )
}
