import React, { useEffect, useState } from "react";
import { isUserDstTx, isUserSrcTx, TrimString } from "./utils/utils";
import { TxItemType, TxStatus, User } from "./utils/types";
import { Button, Card } from "react-bootstrap";
import { Variant } from "react-bootstrap/types";

function pickColor(status: TxStatus): Variant {
    switch (status) {
        case "BROADCASTING":
            return "info";
        case "COMPLETED":
            return "success";
        case "SUBMITTED":
        case "QUEUED":
            return "secondary";
        case "CONFIRMED_1":
        case "CONFIRMED_2":
        case "CONFIRMED_3":
            return "info";
        case "FAILED":
            return "danger";
        default:
            console.log(`Unknown tx status: ${status}, picking default color`);
            return "dark";
    }
}

function TxItem({tx, user, delay}: { tx: TxItemType, user: User, delay: number }) {
    const [visible, setVisible] = useState(true);
    const [err, setErr] = useState<string>();
    const [cancelDisabled, setCancelDisabled] = useState(false);

    useEffect(() => {
        console.log("New TxItem, txId is", tx.txId);
        let t1: NodeJS.Timeout;
        if (delay >= 0) {
            t1 = setTimeout(() => {
                setVisible(false);
            }, delay);
        }
        const t2 = setTimeout(() => setCancelDisabled(true), 3000);
        return () => {
            if (t1) {
                clearTimeout(t1);
            }
            clearTimeout(t2);
        }
    }, [delay, tx]);

    const isTxUserSrc = isUserSrcTx(tx, user);
    const isTxUserDst = isUserDstTx(tx, user);

    function dstText(dstAddress: string, dstUser?: string): string {
        const trimmedDstAddress = TrimString(tx.txDestination, 8);
        if (dstUser) {
            return `${dstUser} (${isTxUserDst ? "YOU" : trimmedDstAddress})`;
        } else {
            return `${trimmedDstAddress} (unknown user)`;
        }
    }

    const cancelTx = () => {
        try {
            console.log(`Sending cancel tx for tx ${tx.txId}...`);
            throw new Error("Tx already reached the blockchain...");
        } catch (err) {
            console.error("Error trying to delete user:", err);
            setErr(`Error trying to delete user: ${err}`);
            setTimeout(() => setErr(undefined), 2000);
        }
    };

    return visible ?
        <Card bg={pickColor(tx.txStatus)} className="mt-4" text={"white"} border="primary">
            <Card.Body>
                <Card.Title>
                    {tx.txAmount}$ (fee: {tx.txFee})
                </Card.Title>
                <Card.Text>
                    From: {tx.txUser} {isTxUserSrc && "(You)"} To: {dstText(tx.txDestination, tx.dstUser)}
                    {" | "}{tx.txStatus}
                </Card.Text>
                <Card.Footer>
                    {new Date(tx.createdAt).toLocaleString()}, id: {tx.txId} {tx.txHash && `, hash: ${TrimString(tx.txHash, 8)}`}
                    {(isTxUserSrc || isTxUserDst) &&
                    <div>
                        {tx.txStatus === "SUBMITTED" &&
                            <Button variant={"danger"} onClick={cancelTx} disabled={cancelDisabled}>
                                Cancel
                            </Button>
                        }
                        {err && <p style={{color: "orangered"}}>{err}</p>}
                    </div>
                    }
                </Card.Footer>
            </Card.Body>
        </Card>
        : <div/>;
}

export default TxItem;
