import { Alert, Button } from "react-bootstrap";
import React, { useState } from "react";

function CopyableDismissible({displayText, copyText}: {displayText: string, copyText: string}) {
    const [show, setShow] = useState(false);
    const [copyState, setCopyState] = useState<{success: boolean, text: string}>();

    async function copyToClipboard(e: any): Promise<void> {
        e.preventDefault();
        if (!copyText) {
            setCopyState({success: false, text: "No text to be copied"});
        } else {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(copyText);
                setCopyState({success: true, text: "Copied"});
            } else {
                setCopyState({success: false, text: "The Clipboard API is not available."});
            }
        }
        setShow(true);
    }

    if (show) {
        return (
            <Alert variant={copyState!.success ? "success" : "danger"}
                   onClose={() => setShow(false)}
                   dismissible>
                <Alert.Heading>{copyState!.text}</Alert.Heading>
            </Alert>
        );
    }
    return (
        <>
            <span>{displayText}</span>
            <Button onClick={copyToClipboard} variant={"warning"}>
                <i className="fa fa-clipboard"/>
            </Button>
        </>
    );
}

export default CopyableDismissible;
