import { useCallback, useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { BaseClientToServerEvents, connectToSocket } from "../utils/sockets";
import { useAuth } from "../auth";
import { Container, Row } from "react-bootstrap";


interface ServerToClientEvents {
    city: (data: string) => void
    tokenInvalid: () => void;
}

interface ClientToServerEvents {
    ip_to_city_ack: () => void;
}


export function CityLiveUpdates () {

    const CITY_PLACEHOLDER = "city placeholder";

    const socketRef = useRef<Socket<ServerToClientEvents, BaseClientToServerEvents & ClientToServerEvents > | null>(null);
    const {token, setTokenInvalid} = useAuth();
    const [city, setCity] = useState<string>(CITY_PLACEHOLDER);
    const [socketConnected, setSocketConnected] = useState(false);
    const [socketId, setSocketId] = useState("");

    const invalidateToken = useCallback(() => {
        setTokenInvalid(true);
    }, [setTokenInvalid]);

    useEffect(() => {
        console.log("Inside city live-updates useEffect");
        const {socket, pingTimer} = connectToSocket(socketRef, token!);
        let placeholderTimeout: NodeJS.Timeout;
        socket.on("city", (city: string) => {
           console.log(`got city: ${city}`);
           setCity(city);
           clearTimeout(placeholderTimeout);
           placeholderTimeout = setTimeout(() => {
              setCity(CITY_PLACEHOLDER);
           }, 3000);
        });
        socket.on("connect", () => {
            console.log(`in city-updates, connected to websockets server! id: ${socket.id}`);
            socket.emit("ip_to_city_ack");
            setSocketConnected(true);
            setSocketId(socket.id);
        });
        socket.on("disconnect", () => {
            console.log(`in city-updates, websocket server disconnected!`);
            setSocketConnected(false);
            setSocketId("");
        });
        socket.on("tokenInvalid", () => {
            console.warn(`Got tokenInvalid from server`);
            invalidateToken();
        });
        return () => {
            console.log("disconnecting from socket...");
            socket?.disconnect();
            clearTimeout(placeholderTimeout);
            clearInterval(pingTimer);
        }
    }, [token, invalidateToken])
    return (
        <>
            <Container>
                <Row className={"mt-5"}>
                    <h2>
                        Current City:
                    </h2>
                </Row>
                <Row>
                    <b>{city}</b>
                </Row>
                <Row className={"mt-5"}>
                    <small>
                        {`socket ${socketConnected ? `connected (${socketId})` : "not connected"}`}
                    </small>
                </Row>
            </Container>
        </>
    )
}
