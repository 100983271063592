import { TrimString } from "./utils/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { Button, Col, Container, Row } from "react-bootstrap";
import CopyableDismissible from "./utils/copyable-dismissible";
import StatusColored from "./status-colored";
import { useFlag, useUnleashContext } from "@unleash/proxy-client-react";

interface DashboardHeaderProps {
    socketId: string;
    socketConnected: boolean;
    counter: number;
    blockchainAvailable: boolean | null;
}


function DashboardHeader({socketId, socketConnected, counter, blockchainAvailable}: DashboardHeaderProps) {

    const {token, user} = useAuth();
    const [isRefreshingBalance, setRefreshBalance] = useState(false);
    const [balance, setBalance] = useState<{balance: number | undefined, lastUpdated: string}>();
    const updateContext = useUnleashContext();
    const rabbitEnabled = useFlag('weird_rabbit');

    console.log(`DASHBOARD-HEADER rendering, bc available: ${blockchainAvailable ?? "unknown"}; rabbit enabled? ${rabbitEnabled}`);

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const fetchBalance = useCallback(async () => {
        console.log(`Fetching Balance... host is ${process.env.REACT_APP_BASE_URL}`);
        await sleep(1500);
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/balance`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (res.ok) {
            console.log("Fetched balance successfully");
            setBalance({balance: (await res.json()).balance, lastUpdated: new Date().toLocaleTimeString()});
        } else {
            console.error("Bad status from fetch (fetch balance):", res.status);
            setBalance({balance: undefined, lastUpdated: "Couldn't fetch balance"});
        }
    }, [token]);

    const handleBalanceRefresh = (e: any) => {
        e.preventDefault();
        setRefreshBalance(true);
        fetchBalance().finally(() => setRefreshBalance(false));
    }

    useEffect(() => {
        fetchBalance().then(() => console.log("finished initial fetchBalance"));
        const timer = setInterval(async () => {
            await fetchBalance();
        }, 1000 * 60);
        return () => clearInterval(timer);
    }, [token, fetchBalance]);

    useEffect(() => {
        console.log("inside useContext useEffect");
        updateContext({ userId: user?.username })
            .then(() => console.log("unleash context updated"));
    }, [user, updateContext])

    const blockchainStateText = () => {
        if (blockchainAvailable === null) {
            return "unknown";
        }
        return blockchainAvailable ? "available": "unavailable";
    }

    return (
        <Container fluid>
            <Row>
                {rabbitEnabled && <Col>
                    <img src={"https://about.emaohi.com/images/rabbit_logo.png"} alt=""/>
                </Col>}
            <Col>
                <p>
                    Balance: <b>{balance && balance.balance?.toPrecision(3)}</b>
                    <Button variant={"info"} onClick={handleBalanceRefresh} className={"m-3"}>
                        <i className={isRefreshingBalance ? "fa fa-refresh fa-spin" : "fa fa-refresh"}/>
                    </Button>
                    (last-update: {balance && balance.lastUpdated})
                </p>
                <p>Socket ({socketId}) <StatusColored state={socketConnected}
                                                          text={socketConnected ? "connected" : "disconnected"} />
                </p>
                <p>Blockchain state: <StatusColored state={blockchainAvailable}
                                                    text={blockchainStateText()} />
                </p>
                <p><b>{counter}</b> updates in this session</p>
            </Col>
            <Col className={"username"}>
                <p><i className="fa fa-user" /> : <b>{user!.username}</b> ({user?.role})</p>
                <CopyableDismissible displayText={`Address: ${TrimString(user!.address, 8)}`} copyText={user!.address} />
            </Col>
            </Row>
        </Container>
    )
}

export default DashboardHeader;
