import React, { useCallback, useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import './dashboard.css';
import { FetchTxs, isUserTx } from './utils/utils';
import { connectToSocket, BaseClientToServerEvents } from "./utils/sockets";
import TxItem from "./tx-item";
import DashboardHeader from "./dashboard-header";
import { TxForm } from "./tx-form";
import { useAuth } from "./auth";
import { TxItemType, TxStatus } from "./utils/types";

interface SocketTxEvent {
    txId: number;
    txUser: string;
    txStatus: TxStatus;
    txDestination: string;
    txAmount: number;
    createdAt: number;
    txFee: number;
    dstUser?: string;
    txHash?: string;
}

interface ServerToClientEvents {
    hello: (data: string) => void;
    txUpdated: (data: SocketTxEvent) => void;
    tokenInvalid: () => void;
    blockchainState: (available: boolean) => void;
}

interface ClientToServerEvents {
    new_txs_ack: () => void;
}

function Dashboard({counter}: {counter: React.MutableRefObject<number>}) {
    const {token, setTokenInvalid, user} = useAuth();
    const [txUpdates, setTxUpdates] = useState<TxItemType[]>([]);
    const [blockchainAvailable, setBlockchainAvailable] = useState<boolean | null>(null);

    const dummySent = useRef<boolean>(false);

    const socketRef = useRef<Socket<ServerToClientEvents, BaseClientToServerEvents & ClientToServerEvents> | null>(null);
    const socketConnected: boolean = socketRef.current?.connected ?? false;
    const socketId: string = socketRef.current?.id ?? "unknown";

    const DUMMY_USER = "DUMMY";
    const txLimit: number = parseInt(process.env.REACT_APP_MAX_TXS ?? "10");

    console.log(`DASHBOARD rendering, txLimit is ${txLimit}, socket connected is ${socketConnected} socket id is ${socketRef.current?.id}, DUMMYSENT is ${dummySent.current}`);

    function upsertTxUpdateFn (socketEvent: SocketTxEvent): React.SetStateAction<TxItemType[]> {
        const {txId, txStatus, txUser, txDestination, txAmount, createdAt, txFee, dstUser, txHash} = socketEvent;
        const dateStr = new Date(createdAt).toLocaleString();
        return (prevItems: TxItemType[]) => {
            const existingEvent = prevItems.find(event => event.txId === txId);
            if (existingEvent) {
                existingEvent.txStatus = txStatus;
                existingEvent.txHash = txHash;
                return [...prevItems];
            }
            return [{txId, txUser, txStatus, txDestination, txAmount, createdAt: dateStr, txFee, dstUser, txHash}, ...prevItems];
        };
    }

    const fetchUnfinishedTxs = useCallback(async () => {
        const unfinishedTxs = await FetchTxs(token!, undefined, false);
        setTxUpdates(prevItems => {
            return [...prevItems, ...unfinishedTxs];
        });
    }, [setTxUpdates, token]);

    const invalidateToken = useCallback(() => {
        setTokenInvalid(true);
    }, [setTokenInvalid]);

    useEffect(() => {
        console.log("Inside dashboard useEffect");

        const {socket, pingTimer} = connectToSocket(socketRef, token!);

        fetchUnfinishedTxs().then(() => console.log("finished to fetch txs (dashboard)"));

        socket.on("connect", () => {
            console.log(`in dashboard, connected to websockets server! id: ${socket.id}`);
            socket.emit("new_txs_ack");
        });

        socket.on("hello", data => {
            console.log(`Got hello with data: ${data}`);
        });

        socket.on("txUpdated", data => {
            const {txUser} = data;
            if (txUser !== DUMMY_USER) {
                console.log(`got txUpdated event with data ${JSON.stringify(data)}`);
                counter.current += 1;
                setTxUpdates(upsertTxUpdateFn(data));
            }  else {
                if (!dummySent.current) {
                    console.log(`got DUMMY txUpdated for the first time`);
                    dummySent.current = true;
                    setTxUpdates(upsertTxUpdateFn(data));
                } else {
                    console.log(`already got DUMMY txUpdated`);
                }
            }
        });

        socket.on("tokenInvalid", () => {
            console.warn(`Got tokenInvalid from server`);
            invalidateToken();
        });

        socket?.on("blockchainState", isAvailable => {
            console.log(`Got new blockchain state update: ${isAvailable ? "available" : "unavailable"}`);
            setBlockchainAvailable(isAvailable);
        });

        socket.on("disconnect", () => {
            console.warn(`Websocket server disconnected!`);
        });
        return () => {
            console.log("disconnecting from socket...");
            socket?.disconnect();
            clearInterval(pingTimer);
        }
    }, [token, fetchUnfinishedTxs, counter, invalidateToken]);


    function calcDelay(txItem: TxItemType): number {
        const SECOND = 1_000;
        const MINUTE = 60 * SECOND;

        const terminalStatuses: TxStatus[] = ["COMPLETED", "FAILED", "DROPPED"];

        if (txItem.txUser === DUMMY_USER) {
            return 5 * SECOND;
        }
        if (terminalStatuses.includes(txItem.txStatus)) {
            return 20 * SECOND;
        }
        return 5 * MINUTE;
    }

    return (
        <div className={"App"}>
            <DashboardHeader counter={counter.current} socketConnected={socketConnected} socketId={socketId}
                             blockchainAvailable={blockchainAvailable}/>
            <div className="Dashboard-updates">
                <TxForm />
                <div className={"tx-updates"}>
                    <h2>Your Updates</h2>
                    {txUpdates.filter(item => isUserTx(item, user!)).slice(0, txLimit).map(item =>
                        <TxItem key={item.txId} delay={calcDelay(item)} tx={item} user={user!}/>)}
                </div>
                <div className={"other-updates"}>
                    <h2>Other Updates</h2>
                    {txUpdates.filter(item => !isUserTx(item, user!)).slice(0, txLimit).map(item =>
                        <TxItem key={item.txId} delay={calcDelay(item)} tx={item} user={user!}/>)}
                </div>
            </div>
        </div>

    )
}

export default Dashboard;
