import { Col, Container, Form, Row } from "react-bootstrap";
import { TxItemType } from "./utils/types";
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { FetchTxs } from "./utils/utils";
import PaginatedTransactions from "./paginated-txs";

export default function Transactions() {

    const {token, user} = useAuth();

    const TX_LIMIT_STORAGE_KEY = "tx_limit";

    const getTxLimit = (): number => {
        const limitFromStorage = localStorage.getItem(TX_LIMIT_STORAGE_KEY);
        if (limitFromStorage !== null) {
            return parseInt(limitFromStorage);
        }
        return 4;
    }

    const [limit, setLimit] = useState<number>(getTxLimit());
    const [txs, setTxs] = useState<TxItemType[]>([]);
    const [err, setErr] = useState<string>();


    const fetchFinishedTxs = useCallback(async () => {
        console.log("Fetching Txs callback...");
        try {
            const completedTxs: any = await FetchTxs(token!, limit, true);
            setTxs(completedTxs);
            setErr("");
        } catch (err: any) {
            console.error("error from fetch txs:", err);
            setErr(`Bad status from fetch txs: ${err}`)
        }
    }, [token, limit]);

    useEffect(() => {
        console.log("in transactions useEffect");
        fetchFinishedTxs().then(() => console.log("finished to fetch txs (useEffect)"));
    }, [fetchFinishedTxs]);

    return (
        <Container className={"mb-3"}>
            <h2>Transaction History <br />(limit of {limit} last transactions)</h2>
            <Row className={"m-4"}>
                <Col md={{span: 2, offset: 5}}>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Set Limit
                            </Form.Label>
                            <Form.Control
                                onChange={e => {
                                    const newLimit = e.target.value;
                                    setLimit(parseInt(newLimit));
                                    localStorage.setItem(TX_LIMIT_STORAGE_KEY, newLimit);
                                }}
                                type="number"
                                value={limit}
                                style={{textAlign:"center"}}/>
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    {err && <p style={{color: "orangered"}}>{err}</p>}
                </Col>
            </Row>

            {txs.length ? <PaginatedTransactions txs={txs} user={user!}/> : <p />}
        </Container>
    );
}
