import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { DecodeExpiredAt, DecodeUser } from "./utils/utils";
import { isAdminRole, isOwnerRole, User } from "./utils/types";

interface AuthContextType {
    token: string | null,
    tokenInvalid: boolean,
    user: User | null,
    setToken: Function,
    removeToken: Function,
    setTokenInvalid: Function,
    expireAt: string | null;
}

export const AuthContext = createContext<AuthContextType>(null!);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}: { children: ReactNode }) {

    const [token, setToken] = useState(sessionStorage.getItem('token'));
    const [tokenInvalid, setTokenInvalid] = useState(false);
    let user: User | null;
    const deleteToken = () => {
        sessionStorage.clear();
        setToken(null);
    }
    const saveToken = (userToken: any) => {
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
    };

    try {
        user = token ? DecodeUser(token) : null;
    } catch (err) {
        console.error(`Problem decoding user from token, deleting token (${err})`);
        user = null;
        deleteToken();
    }

    const expireAt = token ? DecodeExpiredAt(token) : null;

    useEffect(() => {
       console.log("inside AuthProvider useEffect");
    }, [token, tokenInvalid]);

    return (
        <AuthContext.Provider value={{
            setToken: saveToken,
            removeToken: deleteToken,
            setTokenInvalid,
            tokenInvalid,
            token,
            user,
            expireAt
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function RequireAuth({children}: { children: JSX.Element }) {
    let auth = useAuth();

    if (!auth.token) {
        return <Navigate to="/login" replace/>;
    }

    return children;
}

export function RequireAdmin({children}: {children: JSX.Element}) {
    let {token} = useAuth();

    const role = DecodeUser(token!).role;

    if (!isAdminRole(role)) {
        return (
            <h1>UNAUTHORIZED</h1>
        );
    }

    return children;
}

export function RequireOwner({children}: {children: JSX.Element}) {
    let {token} = useAuth();

    const role = DecodeUser(token!).role;

    if (!isOwnerRole(role)) {
        return (
            <h1>UNAUTHORIZED</h1>
        );
    }

    return children;
}
