import jwtDecode from "jwt-decode";
import { TxItemType, User, UserRole } from "./types";

export async function fetchWithTimeout(resource: string, options: RequestInit, timeout?: number) {

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout ?? 5000);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);
    return response;
}

async function handleRes(res: Response): Promise<any> {
    if (!res.ok) {
        console.warn(`bad status from fetch - ${res.status}`);
        if (res.status < 500 && res.status >= 400) {
            if (res.status === 429) {
                throw new Error(`Rate limit, time left: ${res.headers.get("retry-after")} seconds`);
            }
            throw new Error(`Bad request with status ${(await res.json() as any).statusCode}`);
        } else {
            if (res.status === 503) {
                const msg = (await res.json() as any).message;
                return JSON.parse(msg);
            }
            throw new Error(`Internal error with status ${res.status}`);
        }
    } else {
        console.log("res is ok...");
        return res.json();
    }
}

export async function sendPost<T>(url: string, body: object, token?: string): Promise<T> {
    const res = await fetchWithTimeout(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(token && {'Authorization': `Bearer ${token}`})
        },
        body: JSON.stringify(body)
    });
    return await handleRes(res);
}

export async function sendGet<T>(url: string, token?: string): Promise<T> {
    const res = await fetchWithTimeout(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...(token && {'Authorization': `Bearer ${token}`})
        }
    });
    return await handleRes(res);
}

export function DecodeUser(token: string): User {
    const decoded = jwtDecode<{ username: string, publicKey: string, role: UserRole }>(token);
    return {username: decoded.username, address: decoded.publicKey, role: decoded.role};
}

export function DecodeExpiredAt(token: string): string | null {
    const decoded = jwtDecode<{exp: number }>(token);
    if (decoded.exp) {
        return new Date(decoded.exp * 1000).toLocaleTimeString();
    }
    return null;
}

export function isUserSrcTx (item: TxItemType, user: User): boolean {
    return item.txUser === user.username;
}

export function isUserDstTx (item: TxItemType, user: User): boolean {
    return item.txDestination === user.address;
}

export function isUserTx (item: TxItemType, user: User): boolean {

    return isUserSrcTx(item, user) || isUserDstTx(item, user);
}

export function TrimString(s: string, trimTo: number) {
    if (s.length > trimTo) {
        return s.slice(0, trimTo) + "...";
    }
    return s;
}

export async function FetchTxs(token: string, limit?: number, completed?: boolean): Promise<TxItemType[]>{
    let url = `${process.env.REACT_APP_BASE_URL}/api/transactions`;
    if (limit || completed !== undefined) {
        url += "?";
    }
    if (limit) {
        url += `limit=${limit}`;
        if (completed !== undefined) {
            url += `&completed=${completed}`;
        }
    } else if (completed !== undefined) {
        url += `completed=${completed}`;
    }
    return await sendGet(url, token);
}

export function getLogoUrlForUser(username: string) {
    const hashStr = (s: string) => {
        let h = 0;
        for (let i = 0; i < s.length; i++) {
            h += s.charCodeAt(i);
        }
        return h;
    }

    const baseUrl = `${process.env.REACT_APP_LOGO_URL}/images/profiles_logos`;
    const urls = ["man1", "man2", "girl", "beard"];

    const chosenLogo = urls[hashStr(username) % urls.length];

    return `${baseUrl}/${chosenLogo}.png`;
}
