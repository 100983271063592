import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { sendGet } from "./utils/utils";
import { UserFromApi } from "./utils/types";
import UserItem from "./user-item";

export default function Users(){


    const {token} = useAuth();

    const [limit, setLimit] = useState(5);
    const [users, setUsers] = useState<UserFromApi[]>([]);
    const [err, setErr] = useState<string>();
    const [refreshState, setRefreshState] = useState<boolean>(false);

    console.log(`Rendering users component, users count is ${users.length}`);

    const fetchUsers = useCallback(async (limit: number) => {
        console.log("Fetching users...");
        try {
            const res: any = await sendGet(`${process.env.REACT_APP_BASE_URL}/users/all?limit=${limit}`, token!);
            setUsers(res.users);
        } catch (err) {
            console.error("error from fetch users:", err);
            setErr(`Problem fetching users: ${(err as any).message}`)
        }
    }, [token]);

    useEffect(() => {
        console.log("in Users useEffect");
        fetchUsers(limit).then(() => console.log("finished to fetch users (useEffect)"));
    }, [limit, fetchUsers, refreshState]);

    return (
        <Container>
            <h2>RabbitCoin Users (showing last {limit})</h2>
            <Row className={"m-4"}>
                <Col md={{span: 2, offset: 5}}>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Set Limit
                            </Form.Label>
                            <Form.Control
                                onChange={e => setLimit(parseInt(e.target.value))}
                                type="number"
                                value={limit}
                                style={{textAlign:"center"}}/>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <ListGroup>
                    {users && users.map((user, idx) =>
                        <UserItem key={idx} user={user} setUsers={setUsers} setRefreshState={setRefreshState}/>
                    )}
                </ListGroup>
                {err && <p style={{color: "orangered"}}>{err}</p>}
            </Row>
        </Container>
    );
}
