import React from "react";

interface props {
    state: boolean | null;
    text: string;
}

function stateColor(state: boolean | null) {
    if (state === null) {
        return "gray";
    }
    return state ? "green": "red";
}

function StatusColored({state, text}: props) {
    return (
        <b style={{color: stateColor(state)}}>
            {text}</b>
    )
}

export default StatusColored;
