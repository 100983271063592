export type TxStatus = "COMPLETED" | "QUEUED" | "SUBMITTED" | "BROADCASTING" | "FAILED" | "CONFIRMED_1" | "CONFIRMED_2" | "CONFIRMED_3" | "DROPPED";

export type TxItemType = {
    txId: number;
    txUser: string;
    txStatus: TxStatus;
    txAmount: number;
    txDestination: string;
    createdAt: string;
    txFee: number;
    dstUser?: string;
    txHash?: string;
}

export interface User {
    username: string;
    address: string;
    role: UserRole;
}

export interface UserFromApi {
    username: string;
    publicKey: string;
    role: UserRole;
}

export type UserRole = "owner" | "admin" | "peasant";

export function isAdminRole(role: UserRole): boolean {
    return role === "admin" || role === "owner";
}

export function isOwnerRole(role: UserRole): boolean {
    return role === "owner";
}
