import { Button, Card } from "react-bootstrap";
import React, { Dispatch, useState } from "react";
import { isAdminRole, isOwnerRole, UserFromApi } from "./utils/types";
import { getLogoUrlForUser, TrimString } from "./utils/utils";
import { useAuth } from "./auth";
import CopyableDismissible from "./utils/copyable-dismissible";

export default function UserItem({user, setUsers, setRefreshState}: {user: UserFromApi, setUsers: Dispatch<React.SetStateAction<UserFromApi[]>>,
                                 setRefreshState: Dispatch<React.SetStateAction<boolean>>}) {
    const {token, user: currUser} = useAuth();
    const [err, setErr] = useState<string>();

    const deleteUser = async () => {
        try {
            console.log(`Sending delete to wallet for user ${user.username}...`);
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${user.username}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (res.ok) {
                console.log(`User ${user.username} deleted successfully`);
                setUsers(users => users.filter(u => u.username !== user.username));
            } else {
                console.error("Bad status from delete user:", res.status);
                setErr("Bad status from delete user");
            }
        } catch (err) {
            console.error("Error trying to delete user:", err);
            setErr("Error trying to delete user");
        }
    };

    const assignAdmin = async (isAssign: boolean) => {
        const endpoint = isAssign ? "assign_admin" : "revoke_admin";
        try {
            console.log(`Sending ${endpoint} to wallet for user ${user.username}...`);
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${endpoint}/${user.username}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (res.ok) {
                console.log(`User ${user.username} ${endpoint}`);
                setRefreshState(s => !s);
            } else {
                console.error(`Bad status from ${endpoint}:`, res.status);
                setErr(`Bad status from ${endpoint}`);
            }
        } catch (err) {
            console.error(`Error trying to ${endpoint}:`, err);
            setErr(`Error trying to ${endpoint}`);
        }
    };

    return (
        <Card bg={currUser?.username === user.username ? "success" : "info"} className="mt-4" text={"white"} border="primary">
            <Card.Body>
                <Card.Title>
                    <img src={getLogoUrlForUser(user.username)} alt={""} width={"5%"}/>
                    {user.username}({user.role})
                </Card.Title>
                <Card.Text>
                    Address: <CopyableDismissible displayText={TrimString(user.publicKey, 8)} copyText={user.publicKey} />
                </Card.Text>
                { currUser && isAdminRole(currUser.role) &&
                    <Card.Footer>
                        <Button variant={"danger"} onClick={deleteUser} className={"me-2"}>
                            Delete User
                        </Button>
                        {
                            isOwnerRole(currUser.role) && (user.role === "peasant") &&
                            <Button variant={"primary"} onClick={() => assignAdmin(true)}>
                                Assign admin
                            </Button>
                        }
                        {
                            isOwnerRole(currUser.role) && (user.role === "admin") &&
                            <Button variant={"primary"} onClick={() => assignAdmin(false)}>
                                Revoke admin
                            </Button>
                        }
                        {err && <span style={{color: "orangered"}}>{err}</span>}
                    </Card.Footer>}
            </Card.Body>
        </Card>
    )
}
