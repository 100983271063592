import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function About() {
    return (
      <Container className={"mb-3"}>
          <Row>
              <h2>
                  RabbitCoin
              </h2>
          </Row>
          <Row>
              <Col>
                  <img src={"https://about.emaohi.com/images/profiles_logos/rabbit1.png"} alt={"rabbitCoin"} width={"50%"} />
              </Col>
          </Row>
          <Row className={"mt-3"}>
              <p>
                Best digital coin ever!
              </p>
          </Row>
          <Row className={"mt-5"}>
              <p>
                  Made proudly by:
              <a href="https://about.emaohi.com" target="_blank" rel={"noreferrer"}>
                  Emaohi
              </a>
              </p>
          </Row>
      </Container>
    );
}

export default About;
