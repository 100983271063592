import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import { sendPost } from "../utils/utils";
import { useAuth } from "../auth";

function CreateUser() {

    const {token} = useAuth();
    const [username, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [createUserStatus, setCreateUserStatus] = useState<{type?: "error" | "success", fee?: number, text?: string}>({});

    const createUser = async (username: string, password: string) => {
        const body = {username, password};
        return await sendPost<{username: string}>(`${process.env.REACT_APP_BASE_URL}/users`, body, token!);
    }

    const handleCreateUserSubmit = async (e: any) => {
        e.preventDefault();
        console.log(`inside admins' handleSubmit username is ${username} pass is ${password}`);
        try {
            if (!username || !password) {
                setCreateUserStatus({type: "error", text: "username and password must be set"});
                setUserName("");
                setPassword("");
            } else {
                const {username: user} = await createUser(username, password);
                setCreateUserStatus({type: "success", text: `username ${user} created`});
            }
        } catch (err: any) {
            setCreateUserStatus({type: "error", text: `problem with creating user: ${err.message}`});
        } finally {
            setUserName("");
            setPassword("");
        }
    }

    return (
        <Container>
            <Row className={"mt-5"}>
                <Col md={{ span: 4, offset: 4 }}>
                    <h3>Create user</h3>
                    <Form onSubmit={handleCreateUserSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {"Username"}
                            </Form.Label>
                            <Form.Control
                                onChange={e => setUserName(e.target.value)}
                                type="text"
                                value={username}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {"Password"}
                            </Form.Label>
                            <Form.Control
                                onChange={e => setPassword(e.target.value)}
                                type="password"
                                value={password}
                            />
                        </Form.Group>
                        <Button type={"submit"} variant={"info"}>Submit</Button>
                        {createUserStatus?.type === 'success' && <p style={{color: "limegreen"}}>{createUserStatus?.text}</p>}
                        {createUserStatus?.type === 'error' && <p style={{color: "orangered"}}>{createUserStatus?.text}</p>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default CreateUser;
