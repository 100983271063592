import React, { useCallback, useEffect, useState } from "react";
import { sendPost } from "./utils/utils";
import ToggleSwitch from "./toggle-switch";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useAuth } from "./auth";

type TxRequest = {
    destination: string | undefined;
    amount: number | undefined;
    fee: number | undefined;
}

async function sendTx(token: string, userMode: boolean, txReq: TxRequest): Promise<{fee: number}> {
    const {destination, amount, fee} = txReq;
    const body = userMode ? {to_user: destination, amount, fee}: {to_address: destination, amount, fee};
    return await sendPost<{fee: number}>(`${process.env.REACT_APP_BASE_URL}/api/transaction`, body, token);
}

export function TxForm() {

    const {token} = useAuth();

    const [userMode, setUserMode] = useState<boolean>(localStorage.getItem("user_mode") === "true");
    const [destination, setDestination] = useState<string>("");
    const [amount, setAmount] = useState<number>(0);
    const [fee, setFee] = useState<number>(0);
    const [suggestedFee, setSuggestedFee] = useState<number>(0);
    const [status, setStatus] = useState<{type?: "error" | "success", fee?: number, text?: string}>({});

    const fetchLastFee = useCallback(async () => {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/suggested_fee`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (res.ok) {
            const fetchedFee = (await res.json()).fee;
            setSuggestedFee(fetchedFee);
            setFee(fetchedFee || 0);
            console.log(`Fetched fee successfully: ${fetchedFee}`);
        } else {
            console.error("Bad status from fetch suggested fee:", res.status);
        }
    }, [token]);

    useEffect(() => {
        fetchLastFee().then(() => console.log("finished initial fetchLastFee"));
    }, [fetchLastFee]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log(`inside handleSubmit amount is ${amount} dest is ${destination}`);
        try {
            if (amount && destination) {
                const { fee: resultFee } = await sendTx(token!, userMode, {destination, amount, fee});
                setStatus({type: "success", text: "Submitted", fee: resultFee});
            } else {
                console.log(`problem with amount (${amount}) or dest (${destination})`);
                setStatus({type: "error", text: `amount and the dest must be set`});
                setAmount(0);
                setFee(0);
                setDestination("");
            }
        } catch (err: any) {
            setStatus({type: "error", text: `${err.message}`});
        } finally {
            setTimeout(() => {
                setStatus({});
            }, 3000);
        }
    }

    return (
        <div className={"tx-form"}>
            <h3>Create Transaction</h3>
            <ToggleSwitch label={"User?"} setUserMode={setUserMode} userMode={localStorage.getItem("user_mode") === "true"}/>
            <Form onSubmit={handleSubmit}>
                <Row className={"mt-3"}>
                    <Col md={{ offset: 3, span: 6 }}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {userMode ? "Recipient user" : "Destination address"}
                            </Form.Label>
                            <Form.Control
                                onChange={e => setDestination(e.target.value)}
                                type="text"
                                value={destination}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ offset: 3, span: 6 }}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Amount
                            </Form.Label>
                            <Form.Control as={"input"}
                                onChange={e => setAmount(parseInt(e.target.value))}
                                type="number"
                                min={0}
                                value={amount}
                                style={{textAlign:"center"}}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ offset: 3, span: 6 }}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {`Fee (last fee is ${suggestedFee})`}
                            </Form.Label>
                            <Form.Control as={"input"}
                                onChange={e => setFee(parseFloat(e.target.value))}
                                type="number"
                                value={fee}
                                min={0}
                                step={"0.1"}
                                max={(amount ?? 0) / 2}
                                style={{textAlign: "center"}}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={{offset: 3, span: 6}}>
                        <Button variant="info" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
                {status?.type === 'success' && <p style={{color: "limegreen"}}>{status?.text}, fee: {status?.fee}</p>}
                {status?.type === 'error' && <p style={{color: "orangered"}}>{status?.text}</p>}
            </Form>
        </div>
    )
}
