import React, { useRef } from "react";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import './App.css'
import { Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAdmin, RequireAuth, RequireOwner } from "./components/auth";
import Transactions from "./components/transactions";
import Layout from "./components/layout";
import About from "./components/about";
import CreateUser from "./components/admin/create-user"
import Users from "./components/users";
import GoogleLogin from "./components/google_login";
import NotFound from "./components/not-found";
import GetMoney from "./components/admin/get-money";
import ServiceStatus from "./components/admin/service-status";
import Profile from "./components/profile";
import { CityLiveUpdates } from "./components/admin/city-live-updates";

function App() {

    const counter = useRef(0);

    return (
        <AuthProvider>
            <Routes>
                <Route path="login" element={<Login/>}/>
                <Route path="google_login" element={<GoogleLogin/>}/>
                <Route path={"*"} element={<NotFound/>}/>
                <Route path="/" element={
                    <RequireAuth>
                        <Layout counter={counter}/>
                    </RequireAuth>
                }>
                    <Route path="dashboard" element={
                        <Dashboard counter={counter}/>
                    }/>
                    <Route path="transactions" element={
                        <Transactions/>
                    }/>
                    <Route path="users" element={
                        <Users/>
                    }/>
                    <Route path="about" element={
                        <About/>
                    }/>
                    <Route path="profile" element={
                        <Profile/>
                    }/>
                    <Route path="create_user" element={
                        <RequireAdmin>
                            <CreateUser/>
                        </RequireAdmin>
                    }/>
                    <Route path="get_money" element={
                        <RequireOwner>
                            <GetMoney/>
                        </RequireOwner>
                    }/>
                    <Route path="service_status" element={
                        <RequireOwner>
                            <ServiceStatus/>
                        </RequireOwner>
                    }/>
                    <Route path="live_updates" element={
                        <RequireAdmin>
                            <CityLiveUpdates/>
                        </RequireAdmin>
                    }/>
                    <Route path={""} element={<NotFound/>}/>
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;
