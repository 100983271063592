import { useAuth } from "./auth";
import { getLogoUrlForUser } from "./utils/utils";
import { Container, Row } from "react-bootstrap";

function Profile() {
    const {user: currUser} = useAuth();

    const logoUrl = getLogoUrlForUser(currUser!.username);

    return(
        <Container>
            <Row className={"mb-5"}>
                <h3>
                    {currUser?.username}
                </h3>
            </Row>
            <img src={logoUrl} alt={""}/>
        </Container>
    );
}

export default Profile;
