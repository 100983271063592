import { useAuth } from "./auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import {useEffect} from "react";

export default function GoogleLogin() {
    const {setToken} = useAuth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(`search params are ${Array.from(searchParams.entries())}`);
        const token  = searchParams.get("token");
        console.log(`token is ${token}`);
        if (token) {
            setToken(token);
        }
        navigate("/dashboard", {replace: true});
    })

    return (
        <></>
    )
}
